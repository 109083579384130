const Footer = () => {
    return (
        <div className="section bg-dark">
            <div className="container">
                {/* <div className="footer-info-box text-center">
                    <h4 className="title">Let’s work together</h4>
                    <h2 className="mail">
                        <a href="mailto:someone@example.com">hello@andle.com</a>
                    </h2>
                </div> */}

                <div className="footer-copyright-social">
                    <div className="footer-copyright">
                        <p>
                            &copy; 2024 <span> CreativeInco.me</span>{" "}
                        </p>
                    </div>
                    <div className="footer-social">
                        <ul className="social">
                            <li>
                                <a
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    className="youtube"
                                    href="https://youtube.com/creativeincome"
                                >
                                    YouTube
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Footer;
