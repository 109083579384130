const HomeAbout = () => {
    return (
        <div className="section bg-dark overflow-hidden">
            <div className="about-section section-padding mt-n3 mb-n1">
                <div
                    className="about-images"
                    style={{
                        backgroundImage: `url(${process.env.PUBLIC_URL}/images/gui-and-isa.jpg)`,
                    }}
                ></div>

                <div className="container">
                    <div
                        className="row justify-content-end"
                        data-aos="fade-up"
                        data-aos-duration="1200"
                    >
                        <div className="col-lg-6">
                            <div className="about-content">
                                <h3 className="title">Gui Siebert</h3>
                                <p className="about-text">
                                    Gui Siebert is a professional photographer &
                                    filmmaker, founder of CreativeInco.me and
                                    also the instructor at Stock Creators.
                                </p>
                                <p>
                                    &ldquo;Selling Stock Footage & Stock
                                    Photography has changed my financial life
                                    completely. The passive income allows me to
                                    spend less days freelancing and more time on
                                    my family and the projects I love.&ldquo;
                                </p>
                                <p>
                                    &ldquo;I teach because it is the best way to
                                    show gratitude to those who taught me what I
                                    know today.&ldquo;
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HomeAbout;
